<script>
import { global } from './utils'
export default {
  name: 'PasswordReset',
  props: {
    resetToken: String,
  },
  data() {
    return {
      global,
      errors: {},
      loadings: {},
      emailSent: false,
      passwordSet: false,
      form: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    setError(name) {
      this.$set(this.errors, name, true)
    },
    clearError(name) {
      this.$set(this.errors, name, false)
    },
    setLoading(name) {
      this.$set(this.loadings, name, true)
    },
    clearLoading(name) {
      this.$set(this.loadings, name, false)
    },
    resetPassword() {
      this.isEmailModified && this.resetPasswordEndpoint()
    },
    resetPasswordEndpoint() {
      this.setLoading('resetPasswordEndpoint')
      this.clearError('resetPasswordEndpoint')
      this.global.debug('sending reset password email')
      const body = {
        email: this.form.email,
      }
      this.axios
        .post(this.$root.apiUrl + '/agitator/users/password_resets/', body)
        .then((response) => {
          this.global.debug(response.data)
          if (response.data.status) {
            this.emailSent = true
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('resetPasswordEndpoint')
          this.clearLoading('resetPasswordEndpoint')
        })
        .finally(() => this.clearLoading('resetPasswordEndpoint'))
    },
    changePassword() {
      this.isChangePasswordModified && this.changePasswordEndpoint()
    },
    changePasswordEndpoint() {
      this.setLoading('changePasswordEndpoint')
      this.clearError('changePasswordEndpoint')
      this.global.debug('setting new password')
      const body = {
        email: this.form.email,
        password_reset_token: this.resetToken,
        new_password: this.form.password,
      }
      this.axios
        .post(this.$root.apiUrl + '/agitator/users/password_changes/', body)
        .then((response) => {
          this.global.debug(response.data)
          if (response.data.status) {
            this.passwordSet = true
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('changePasswordEndpoint')
          this.clearLoading('changePasswordEndpoint')
        })
        .finally(() => this.clearLoading('changePasswordEndpoint'))
    },
  },
  computed: {
    isEmailModified() {
      return this.form.email
    },
    isResetPasswordLoading() {
      return (
        this.loadings['resetPasswordEndpoint'] &&
        this.loadings['resetPasswordEndpoint'] === true
      )
    },
    isChangePasswordModified() {
      return this.form.email && this.form.password
    },
    isChangePasswordLoading() {
      return (
        this.loadings['changePasswordEndpoint'] &&
        this.loadings['changePasswordEndpoint'] === true
      )
    },
    isChangePasswordError() {
      return (
        this.errors['changePasswordEndpoint'] &&
        this.errors['changePasswordEndpoint'] === true
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
